import React, { useState, useRef, useEffect } from "react";
import { history } from "../helpers/history";
import axios from "../api/index";
import YouTube from "react-youtube";
import { useParams } from "react-router-dom";
import platform from "platform";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faFile,
  faPenToSquare,
  faCircleQuestion,
  faArrowTurnDown,
  faFlipHorizontal,
} from "@fortawesome/free-solid-svg-icons";
// import Alert from "./Alert";
class Session extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loading: false,
      user: {},
      video: {},
      startPlay: 0,
      playtime: 0,
      pausePlay: true,
      seconds: "",
      setSeconds: "",
      params: {},
      watchedflag: false,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.checkVideo(), 300000);

    const id = this.props.match.params.id;

    this.setState({
      loading: true,
    });

    let tokenString = sessionStorage.getItem("token");
    let userToken = JSON.parse(tokenString);
    let userString = sessionStorage.getItem("user");
    let currentUser = JSON.parse(userString);

    if (!currentUser) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
    }
    if (!userToken) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
    }

    this.setState({
      loading: true,
      user: currentUser,
    });

    this.fetchdata(id);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchdata(id) {
    let video = sessionStorage.getItem("video");

    // console.log(this.id);
    this.setState({
      loading: true,
    });
    return axios({
      url: `/api/video/${id}`,
    })
      .then((res) => {
        this.setState({
          loading: false,
          video: res.data.video,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        if (err.response.status) {
          this.setState({
            video: false,
          });
        }
      });
  }
  changeDialog = (e, index) => {
    e.preventDefault();
    this.setState({
      activeIndex: index,
    });
  };

  startQuiz(quiz) {
    sessionStorage.setItem("quiz", quiz[0].id);
    history.push("/quiz");
    window.location.reload();
  }
  onReady = (value) => {
    //TODO:
  };
  onPlay = (value) => {
    this.setState({
      startPlay: new Date(),
      pausePlay: false,
    });
  };

  onPause = (value) => {
    // console.log(value.target.getCurrentTime());
    this.setState({
      pausePlay: true,
      playtime:
        this.state.playtime + (new Date().getTime() - this.state.startPlay),
      startPlay: 0,
    });
    // console.log("before sending video");
    // console.log(this.state.playtime);
    if (this.state.playtime >= 900000 && !this.state.watchedflag) {
      this.wathedVideo();
    }
  };

  onEnd = (value) => {
    // console.log("aaa");
    this.setState({
      pausePlay: true,
      playtime:
        this.state.playtime + (new Date().getTime() - this.state.startPlay),
      startPlay: 0,
    });
    if (this.state.playtime >= 900000) {
      this.wathedVideo();
    }
  };

  checkVideo() {
    // console.log("hi deep");

    if (this.state.pausePlay === false) {
      // console.log("hi deep");
      this.onPause();
      this.onPlay();
    }
  }

  wathedVideo() {
    this.setState({
      loading: true,
    });
    const { user, video } = this.state;
    return axios({
      url: `/api/watched`,
      body: {
        user: user.id,
        video: video.id,
        platform: platform.name,
      },
    })
      .then((res) => {
        if (res.data[0] === "success") this.setState({ watchedflag: true });

        this.setState({
          loading: false,
          playtime: 0,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  // Window.addEventListener('beforeunload', function (e) {
  //     // Cancel the event
  //     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  //     this.wathedVideo();
  //    return e.returnValue = '';
  //   });
  render() {
    const { video } = this.state;
    return (
      <div className="container-fluid">
        {!video && (
          <h1 className="alert alert-danger text-center">
            ...حدث خطأ! من فضلك أعد تحميل الصفحة أو تأكد من الرابط
          </h1>
        )}
        <div className="row">
          <div className="col-lg-3 col-md-4 ml-auto">
            {/* Side navbar */}
            <nav className="side-navbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="#video">
                    مشاهدة الفيديو
                    <FontAwesomeIcon
                      icon={faVideo}
                      style={{ marginLeft: "8px" }}
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#exam"
                    style={{ marginLeft: "33px" }}
                  >
                    ابدأ الامتحان
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ marginLeft: "8px" }}
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#files">
                    ملفات المحاضرة
                    <FontAwesomeIcon
                      icon={faFile}
                      style={{ marginLeft: "8px" }}
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#question"
                    style={{ marginLeft: "42px" }}
                  >
                    ارسل سؤال
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      style={{ marginLeft: "10px" }}
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="container">
            <div className="row justify-content-center" id="video">
              {video && video.title && (
                <p className="video-title">{video.title}</p>
              )}
              {video && video.speaker && (
                <p className="video-speaker">{video.speaker}</p>
              )}
              <div className="video-container" id="exam">
                <div className="video-frame">
                  <YouTube
                    videoId={video.link}
                    id={video.link}
                    onReady={this.onReady}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onEnd={this.onEnd}
                    onError={this.onError}
                    onStateChange={this.onStateChange}
                  />
                </div>
              </div>
              <div
                className="col-lg-12 col-md-12 center-content"
                style={{ marginTop: "5%" }}
              >
                {!video.watched && (
                  <button
                    className="btn btn-primary btn-lg btn-block btn-warning"
                    onClick={() => {
                      alert(
                        "سيكون الامتحان متاح لك عند مشاهدة الفيديو بالكامل.. عندما ينتهي الفيديو قم بتوقيف الفيديو(pause video) و اعادة تحميل الصفحة (reload page) و سيظهر زر ابدأ الامتحان بالاخضر اذا قمت بمشاهدة الفيديو بالكامل"
                      );
                      this.checkVideo();
                    }}
                  >
                    ابدأ الامتحان
                  </button>
                )}
                {video.watched && (
                  <button
                    className="btn btn-success btn-lg btn-block"
                    onClick={() => {
                      this.startQuiz(video.quizzes);
                    }}
                    style={{ width: "100%" }}
                  >
                    <span>
                      ابدأ الامتحان{" "}
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{ marginLeft: "5px" }}
                      />
                    </span>
                  </button>
                )}
              </div>
              {/* Files section */}
              <div className="card text-center" id="files">
                <div
                  className="card-header text-center text-white"
                  style={{ backgroundColor: "#646ecb" }}
                >
                  ملفات المحاضرة
                  <FontAwesomeIcon
                    icon={faFile}
                    className="mr-2"
                    style={{ marginLeft: "8px" }}
                  />
                </div>
                <div className="card-body">
                  {video && video.pdf && (
                    <iframe
                      src={video.pdf}
                      width="100%"
                      height="700px"
                    ></iframe>
                  )}
                </div>
              </div>
              {/* Question section */}
              <div class="card" id="question">
                <div
                  dir="rtl"
                  style={{ backgroundColor: "#646ecb" }}
                  class="card-header text-center text-white"
                >
                  لو عندك سؤال في المحاضرة ابعته هنا
                  <FontAwesomeIcon
                    icon={faArrowTurnDown}
                    className="fa-flip-horizontal"
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div class="card-body">
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfHeIvCTR1YGHfcGtQR9VzSN0hRYsd5-o502vIKwE07GDCtzA/viewform?embedded=true"
                    width="100%"
                    height="425"
                    frameborder="0"
                    marginHeight="0"
                    marginWidth="0"
                  >
                    Loading…
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// window.addEventListener('beforeunload', function (e) {
//   // Cancel the event
//   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
//   // Chrome requires returnValue to be set
//   e.returnValue = '';
//   return 1;

// });

export default Session;
