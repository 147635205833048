import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../api/index";

export default function Alert() {
  const [show, setShow] = useState(false);
  var [currentAlert, setCurrentAlert] = useState("");
  useEffect(() => {
    const showAlert = async () => {
      return axios({
        url: `/api/alert`,
        method: "GET",
      })
        .then((res) => {
          let data = res.data.data;
          if (data === currentAlert || data === "" || data === null) {
            // console.log("the same message");
          } else {
            setShow(true);
            setCurrentAlert(data);
            currentAlert = data;
          }
        })
        .catch((err) => {});
    };

    const intervalId = setInterval(showAlert, 120000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>الرجاء الانتباه .. يوجد خبر جديد</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{currentAlert}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShow(false)}>
          موافق
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
