import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "react-alert-confirm/dist/index.css";
import { alert } from "react-alert-confirm";
import platform from "platform";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import axios from "./api/index";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Quiz from "./components/QuizScreen";
import Session from "./components/SessionScreen";
import Payment from "./components/Payment";
import Navbar from "./components/Navbar";
//https://www.npmjs.com/package/react-alert-confirm
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import Alert from "../src/components/Alert";

const App = () => {
  const [register, setRegister] = useState(0);
  const [login, setLogin] = useState(0);

  const loginDetails = async () => {
    return axios({
      url: `/api/get/auth/info`,
      method: "GET",
    })
      .then((res) => {
        // console.log("helloooooooooo");
        let data = res.data.data;
        //  data = data;
        //  let parser = new DOMParser();
        //  let doc = parser.parseFromString(data, 'text/html');
        //  data = doc.body;

        data = JSON.parse(data);

        data.forEach((element) => {
          if (element.name === "register") {
            setRegister(parseInt(element.value));
            sessionStorage.setItem("register", parseInt(element.value));
          }
          if (element.name === "login") {
            setLogin(parseInt(element.value));
            sessionStorage.setItem("login", parseInt(element.value));
          }
        });
      })
      .catch((err) => { });
  };

  loginDetails();

  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  const userString = sessionStorage.getItem("user");
  let currentUser = JSON.parse(userString);

  const dispatch = useDispatch();
  const [alertt, setAlert] = useState("");
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage());
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      let reload = 120000;
      const autoRefresh = setInterval(() => {
        getNews();
        // console.log('here ana b3ml refresh',  new Date())
      }, reload);

      return () => {
        // Clear previous timeout
        clearInterval(autoRefresh);
      };
    }
  }, [currentUser]);

  const getNews = async () => {
    return axios({
      url: `/api/alert`,
      method: "GET",
    })
      .then((res) => {
        let data = res.data.data;
        //  data = data;
        //  let parser = new DOMParser();
        //  let doc = parser.parseFromString(data, 'text/html');
        //  data = doc.body;
        // console.log(data);
        if (data === alertt || data === "" || data === null) {
          // console.log("the same message");
        } else {
          setAlert(data);
          alert({
            title: "الرجاء الانتباة..يوجد خبر جديد",
            content: data,
            okText: "تم",
            className: "text-right",
          });
          // confirm('test')
        }
      })
      .catch((err) => { });
  };

  const logOut = () => {
    return axios({
      url: `/api/logout`,
      method: "POST",
    })
      .then((res) => {
        currentUser = false;
        dispatch(logout());
        sessionStorage.clear();
        window.location.reload();
      })
      .catch((err) => { });
  };

  if (!currentUser) {
    currentUser = false;
    localStorage.removeItem("user");
    history.push("/");
    sessionStorage.clear();
  }
  if (!userToken) {
    currentUser = false;
    localStorage.removeItem("user");
    history.push("/");
    sessionStorage.clear();
  }

  return (
    <Router history={history}>
      <div>
        <Navbar currentUser={currentUser} logOut={logOut} register={register} login={login} />
        <div className="">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/session/:id" component={Session} />
            <Route exact path="/quiz" component={Quiz} />
            <Route exact path="/payment" component={Payment} />
          </Switch>
          <Alert />
        </div>
      </div>
    </Router>
  );
};

export default App;
