import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import image from "../assets/images/defaultPP.png";
import CheckButton from "react-validation/build/button";
import axios from "../api/index";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faKey,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger custom-alert" role="alert">
        !هذا الحقل مطلوب
      </div>
    );
  }
};

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const form = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [done, setDone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };
  const onChangeDone = (e) => {
    const username = e;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const history = useHistory();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (username !== "" || password !== "") {
      return axios({
        url: "/api/login",
        body: {
          username: username,
          password: password,
        },
        headers: {},
        method: "POST",
      })
        .then((res) => {
          if (res.data.data.user["payed"] == 1) {
            sessionStorage.setItem(
              "token",
              JSON.stringify(res.data.data.token)
            );
            sessionStorage.setItem("user", JSON.stringify(res.data.data.user));
            setLoading(false);
            // setDone('سيتم فتح الدخول يوم 21/6 الساعة ٢ظ، ولقد او تلقينا الاشتراك بنجاح ');
            history.push("/profile");
            window.location.reload();
            // console.log("he is not paied");
          } else {
            setLoading(false);
            setDone("لقد تم الدخول بنجاح،");
            setMessage("!لكن لم نتلقي اشتركك حتي الان");
          }
          return;
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            setMessage("برجاء التاكد من رقم التسجيل ورقم الموبايل");
          } else {
            setMessage("خضأ، برجاء التاكد من رقم التسجيل ورقم الموبايل");
          }
          console.warn("Failed to load ");
        })
        .catch((err) => {
          setLoading(false);
          console.warn("Failed to load ");
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/profile" />;
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img src={image} alt="profile-img" className="profile-img-card" />
        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group">
            <label htmlFor="username">
              اسم المستخدم
              <FontAwesomeIcon
                icon={faUser}
                style={{
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  color: "rgb(84, 105, 212)",
                }}
              />
            </label>
            <Input
              type="text"
              className="form-control custom-input"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
            />
          </div>

          <div className="form-group" style={{ paddingTop: "5px" }}>
            <label
              htmlFor="password"
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
            >
              رقم المرور
              <FontAwesomeIcon
                icon={faKey}
                style={{ marginLeft: "5px", color: "#cba709" }}
              />
              <p>(حسب الرسالة على الواتساب وبكود البلد)</p>
            </label>
            <div className="form-group">
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control custom-input"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  required
                />
                <div className="input-group-append">
                  <span
                    className="input-icon"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <button
              className="btn btn-primary btn-block custom-button"
              disabled={loading}
            >
              {loading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span> دخول </span>
              )}
            </button>
          </div>

          {done && (
            <div className="form-group">
              <div className="alert alert-info" role="alert">
                {done}
              </div>
            </div>
          )}
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Login;
