import React from "react";
import { history } from "../helpers/history";
import axios from "../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowRight,
  faCircleArrowRight as faCircleArrowRightFlipped,
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        !هذا الحقل مطلوب
      </div>
    );
  }
};

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.answersFinal = [];
    this.state = {
      results: [],
      loading: false,
      user: {},
      video: {},
      message: "",
      questions: [],
      currentQuestionIndex: 0,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    let tokenString = sessionStorage.getItem("token");
    let userToken = JSON.parse(tokenString);
    let userString = sessionStorage.getItem("user");
    let currentUser = JSON.parse(userString);

    if (!currentUser) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
    }
    if (!userToken) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
    }

    this.setState({
      loading: true,
      user: currentUser,
    });
    this.fetchdata();
  }

  fetchdata() {
    let quiz = sessionStorage.getItem("quiz");
    this.setState({
      loading: true,
    });
    return axios({
      url: `/api/quiz/${quiz}`,
    })
      .then((res) => {
        // console.log(res);
        let questions = res.data.quiz;
        this.setState({
          loading: false,
          questions: res.data.quiz,
          answersFinal: questions.map((question) => ({
            questionsID: question.id,
            answer: null,
          })),
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          this.setState({
            message: "لا يوجد امتحان لهذه المحاضرة",
            questions: [],
            loading: false,
          });
        }
      });
  }

  changeDialog = (e, index) => {
    e.preventDefault();
    this.setState({
      activeIndex: index,
    });
  };

  onChangeAnswer = (answer, question) => {
    const { currentQuestionIndex, answersFinal } = this.state;
    const newAnswersFinal = [...answersFinal]; // Create a copy of the answersFinal array
    newAnswersFinal[currentQuestionIndex] = {
      questionsID: question.id,
      answer: answer.target.value,
    };
    this.setState({
      answersFinal: newAnswersFinal,
    });
  };

  submit() {
    this.setState({
      loading: true,
    });
    const { answersFinal, user } = this.state;
    answersFinal.forEach((element) => {
      if (element.answer === null) {
        this.setState({
          message: "!من فضلك أجب علي جميع الاسئلة",
        });
        return;
      }
    });
    return axios({
      url: `/api/answer`,
      body: {
        user: user.id,
        answers: answersFinal,
        quiz: sessionStorage.getItem("quiz"),
      },
      method: "POST",
    })
      .then((res) => {
        this.setState({
          loading: false,
        });
        sessionStorage.setItem("quiz", null);
        history.push("/profile");
        window.location.reload();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  renderQuestion() {
    const { questions, currentQuestionIndex, answersFinal } = this.state;
    const question = questions[currentQuestionIndex];
    const userAnswer = answersFinal[currentQuestionIndex]?.answer;

    // Check if the current question is answered
    const isAnswered = userAnswer !== null;

    return (
      <div className="quiz-card-container">
        <div className={`quiz-card ${isAnswered ? "" : "unanswered"}`}>
          <p className="quiz-card-question">
            {currentQuestionIndex + 1}. {question.question}
          </p>

          <div className={`quiz-card-answer ${isAnswered ? "" : "unanswered"}`}>
            <select
              className="quiz-card-select"
              key={currentQuestionIndex}
              value={userAnswer || 0}
              onChange={(event) => this.onChangeAnswer(event, question)}
              validations={[required]}
            >
              <option value={0} disabled>
                اختر الاجابة الصحيحة
              </option>
              {question &&
                question.options &&
                question.options.length > 0 &&
                question.options.map((option, idx) => (
                  <option key={idx} value={option.id}>
                    {option.option}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { questions, loading, message, currentQuestionIndex, answersFinal } =
      this.state;

    // Check if there are no questions
    if (!questions || questions.length === 0) {
      return <p>...حدث خطأ! من فضلك أعد تحميل الصفحة</p>;
    }

    const currentQuestion = questions[currentQuestionIndex];

    return (
      <div className="quiz-card-container">
        <div className="quiz-card-number-list">
          {questions.map((question, index) => (
            <button
              key={index}
              className={`quiz-card-number ${index === currentQuestionIndex
                  ? "active"
                  : answersFinal[index]?.answer === null
                    ? "unanswered"
                    : ""
                }`}
              onClick={() => this.setState({ currentQuestionIndex: index })}
            >
              <span
                id={`question-number ${answersFinal[index]?.answer === null ? "unanswered" : ""
                  }`}
              >
                {index + 1}
              </span>
            </button>
          ))}
        </div>

        <div className="quiz-card">
          {this.renderQuestion()}

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

          <div className="quiz-card-buttons">
            {currentQuestionIndex !== 0 && (
              <button
                className="btn-previous"
                onClick={() =>
                  this.setState({
                    currentQuestionIndex: currentQuestionIndex - 1,
                  })
                }
              >
                <FontAwesomeIcon icon={faCircleArrowLeft} />
                <span style={{ marginLeft: "5px" }}> السابق</span>
              </button>
            )}

            {currentQuestionIndex !== questions.length - 1 ? (
              <button
                className="btn-next"
                onClick={() =>
                  this.setState({
                    currentQuestionIndex: currentQuestionIndex + 1,
                  })
                }
              >
                <span>التالي</span>
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  style={{ marginLeft: "5px" }}
                />
              </button>
            ) : (
              <button
                className="btn-submit"
                onClick={() => {
                  this.submit();
                }}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginRight: "5px" }}
                  ></span>
                )}
                <span style={{ marginLeft: "5px" }}>إنهاء الامتحان</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Quiz;
