const mode = 'prod'; // opts = ['local', 'prod', 'test']

// local development flags
let localServer = mode === 'local';

//let serverIP = 'https://private-e5b4f-dawrytma5tobeen.apiary-mock.com'; // Test/staging Env.
let serverIP = 'https://testingengaged.stmarkos.org'; // Test/staging Env.

// let serverIP = 'https://engaged.stmarkos.org'; // Test/staging Env.
let API_ROOT = serverIP + '/api';

if (mode === 'prod') {
  serverIP = 'https://apiengaged.stmarkos.org'; // Multi-tenancy Production env.
  API_ROOT = serverIP + '/api';
}

if (mode === 'local') {
  // Local Development mode
  serverIP = 'http://localhost:1337';
  API_ROOT = serverIP;
}
const API_VERSION = 3;
const APP_VERSION = '3.0.0';


export {
  mode,
  serverIP,
  API_ROOT,
  APP_VERSION,
  localServer,
  API_VERSION,
};