import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import logo from "../assets/images/VioletCross.png";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from "../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { login } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Payment = (props) => {
  // console.log("here ana fl payent");
  const form = useRef();
  const checkBtn = useRef();

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  return (
    <div className="col-xl payment-container">
      <div className="card-container-lg">
        <div className="container-logo" style={{ textAlign: "center" }}>
          <img src={logo} alt="Logo" style={{ width: 200, height: 200 }} />
        </div>

        <div className="alert alert-warning" style={{ textAlign: "center" }}>
          <h3>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{ marginLeft: "5px" }}
            />
            برجاء قراءة هذه الصفحة جيدًا{" "}
          </h3>
        </div>

        <div className="content-container">
          <p className="content-title">
            أهلًا ومرحبًا بكم في الدورة السابعة والخمسون لإعداد المخطوبين
            والمقبلين على الزواج في الفترة من الإثنين 11 ديسمبر إلى الخميس 14
            ديسمبر 2023 بكنيسة مارمرقس بمصر الجديدة.
          </p>
          <ul>
            <li>
              {" "}
              يتم تفعيل حسابك خلال 48 ساعة من دفع مبلغ الإشتراك (200 جنيه
              للفرد) من خلال الطرق الآتية:
            </li>
            <li style={{ textAlign: "right" }}>
              الدفع عند سكرتارية الآباء بالكنيسة يوميًا من ١٠ صباحًا الي ٨
              مساءًا وابلاغهم بالاسم ورقمك الكودي (الذي تم ارساله في رسالة واتس
              اب وتليفون محمول.)
            </li>
            <hr />
          </ul>
        </div>
        <span style={{ textAlign: "right" }}>
          <li>تحويل الإشتراك من أي محفظة بنكية او الكترونية (اورانج كاش – فودافون كاش – انستاباي ...) على محفظة فودافون كاش رقم : 01019966994</li>
          في حالة التحويل على محفظة فودافون كاش يجب ارسال البيانات التالية على الواتس اب الخاص بالدورة حتى نتمكن من تفعيل الاشتراك :
          {/* <li> تحويل الإشتراك على فودافون كاش رقم : 01019966994 </li> */}
          <li>الرقم الذي تم التحويل منه</li>
          <li>	التاريخ والوقت</li>
          <li>	الأرقام الكودية للمشتركين المسدد لهم الاشتراك</li>
          <li>	سكرين شوت بالتحويل سواء من الابليكيشن او كود المعاملة</li>
          {/* <li>
            يمكن التحويل من خلال أي رقم فودافون به خدمة فودافون كاش. يمكن
            التحويل من محافظ الكترونية أخرى أو فوري أو ماكينات الدفع الأخرى،
          </li> */}
          {/* <p style={{ color: "red" }}>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ marginLeft: "5px" }}
            />
            ولكن يجب إرسال رسالة تأكيد قبل التحويل بنصف ساعة ليتمكن أحد الخدام
            من تأكيد استقبال الإشتراك على نفس الرقم.
          </p> */}
          <hr />
        </span>
        <span style={{ textAlign: "right" }}>
          الدفع عن طريق بطاقات الائتمان على موقع الكنيسة على الرابط: https://donation.stmarkos.org
          <br />
          وعمل حساب للتبرع واختيار الخدمة (دورة إعداد المخطوبين والمقبلين على الزواج) ودورية التبرع مرة واحدة.
          <div>
            في حالة التحويل بالدفع اونلاين ببطاقات الائتمان يجب ارسال البيانات التالية على الواتس اب الخاص بالدورة حتى نتمكن من تفعيل الاشتراك :
          </div>
          <li>
            الأسماء المسدد لها الاشتراك
          </li>
          <li>
            الأرقام الكودية للاسماء المسدد لها الاشتراك
          </li>
          <li>
            تاريخ ووقت المعاملة والمبلغ المدفوع
          </li>
          <li>
            الايميل المسجل على موقع تبرعات الكنيسة
          </li>
          <li>
            سكرين شوت باتمام المعاملة
          </li>


          {/* الدفع عن طريق بطاقات الائتمان على موقع الكنيسة على الرابط:{" "}
          https://donation.stmarkos.org وعمل حساب للتبرع واختيار الخدمة (دورة
          إعداد المخطوبين والمقبلين على الزواج) ودورية التبرع مرة واحدة. */}
        </span>
        <hr />
        {/* <span style={{ textAlign: "right" }}>
          في حالة الدفع عن طريق فودافون كاش أو فوري أو بطاقات الائتمان رجاء
          ارسال رسالة على الواتس اب الخاص بالدورة بها التالي:
          <li>الاسم</li>
          <li>الرقم الكودي</li>
          <li>تاريخ ووقت المعاملة والمبلغ المدفوع</li>
          <li>رقم التليفون الذي تم التحويل منه</li>
          <hr />
          <div style={{ fontSize: "18px", fontWeight: "500" }}>
            <h3>اثبات التحويل:</h3>
            <ul>
              <li>إيصال السداد من فوري ورقم المعاملة</li>
              <li>سكرين شوت بالتحويل في حالة التحويل من خط فودافون</li>
              <li>سكرين شوت من تأكيد التحويل في حالة الدفع ببطاقات الائتمان</li>
            </ul>
          </div>
          <p
            style={{
              textAlign: "center",
              color: "red",
              margin: "10px",
              fontWeight: "500",
              fontSize: "20",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ marginLeft: "5px" }}
            />
            في حالة عدم إرسال هذه البيانات نأسف لعدم تمكننا من تأكيد دفع
            الاشتراك.
          </p>
          <hr />
        </span> */}
        <div style={{ fontWeight: "bold" }}>
          في حالة عدم إرسال بيانات طريقة الدفع بدقة نأسف لعدم تمكننا من تأكيد دفع الاشتراك.</div>
        <hr />
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            اخر موعد للدفع قبل بداية الدورة باسبوع على الأقل و يفضل بعد الحجز مباشرة لحصر الاعداد
            {/* آخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل. */}
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            تبدأ المحاضرات يوميًا في مواعيد الدورة المعلنة الساعة ٧،٠٠ مساءًا
            بواقع محاضرتين يوميًا بينهم استراحة قصيرة
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            المحاضرات بالحضور بالكنيسة ويمكن الحضور اونلاين على موقع دورة
            المخطوبين والدخول من خلال اسم المستخدم وكلمة المرور الذين تم ارسالهم
            سابقًا.
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            في حالة الحضور اونلاين لا يتم إثبات الحضور إلا بعد مشاهدة المحاضرة
            كاملة ويرجى عدم إغلاق المتصفح أو غلق الصوت حتى يتمكن النظام من إثبات
            الحضور.
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            {" "}
            بعد الحضور سواء بالكنيسة أو اونلاين سيتم اتاحة الفرصة لك لتقوم بحل
            الامتحان الخاص بكل محاضرة على موقع دورة المخطوبين ونتيجته تكون إما
            نجاح أو رسوب ويمكن في حالة الرسوب إعادة مشاهدة المحاضرة وإعادة حل
            الامتحان الا محاضرات اليوم الأخير لا يمكن اعادتهم.
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            لتحصل على الشهادة الخاصة بالدورة يجب حضور المحاضرات كاملة والنجاح في
            كل الامتحانات.
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            المحاضرات الأساسية كلها متاحة للحضور او الاونلاين ولكن قد توجد بعض المحاضرات الإضافية او الأسئلة او الفقرات التي تكون متاحة للحاضرين فقط
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            يغلق باب تسجيل الأسماء بالكنيسة بعد بدء المحاضرة ب ١٠ دقائق على اقصى
            حد.
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            فور طباعة الشهادات يتم إرسال رسالة بموعد استلامها من سكرتارية الآباء
            بالكنيسة.
          </li>
        </span>
        <span style={{ textAlign: "right" }}>
          <li dir="rtl">
            يوجد يوم روحي ترفيهي اختياري ببيت دار الينبوع يوم السبت 16 ديسمبر 2023 باشتراك منفصل يتم الحجز به اول يومين بالدورة.
          </li>
        </span>
        <ul style={{ textAlign: "right" }}>
          <li>
            {" "}
            لأي استفسارات أخرى التواصل من خلال رسائل الواتس اب فقط على الرقم:
            01019966994
          </li>
          <li dir="rtl">
            {" "}
            أو رسائل البريد الإلكتروني على: engaged@stmarkos.org
          </li>
          <div className="line-wrapper">
            <div className="line"></div>
            <p
              style={{
                textAlign: "center",
                color: "#CBA709",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              والرب يبارك حياتكم ويملأها بكل فرح وسرور
            </p>
            <div className="line"></div>
          </div>
        </ul>
      </div>
    </div >
  );
};

export default Payment;
