import axios from 'axios';
import promise from 'promise';
import AsyncStorage from '@react-native-community/async-storage';
import { localServer, serverIP } from './CONSTANTS';

// Add a request interceptor
const axiosInstance = axios.create();
// Intercepting all API requests
axiosInstance.interceptors.request.use(
  async function (config) {
    // If the header does not contain the token and the url not public, redirect to login
    // TODO: add actual jwToken
    const tokenString = sessionStorage.getItem('token');
    const accessToken = JSON.parse(tokenString);
  
    config.headers['Accept'] = 'application/json';
    //config.headers['Authorization'] = accessToken;

    // Injecting the API server IP
    if (localServer) {
      config.url = serverIP + config.url.replace('/api', '');
    } else {
      config.url = serverIP + config.url;
    }

    //if token is found add it to the header
    if (accessToken) {
      if (config.method !== 'OPTIONS') {
        config.headers.Authorization = 'Bearer ' + accessToken;
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return promise.reject(error);
  },
);
// config should be an object
export default config => {
  const {
    url,
    method = 'GET',
    body = {},
    actions = null,
    disableToast = false,
  } = config;
  let requestPromise = axiosInstance;
  switch (method) {
    case 'POST':
      requestPromise = requestPromise.post(url, body);
      break;
    case 'GET':
      requestPromise = requestPromise.get(url, { params: { ...body } });
      break;
    case 'DELETE':
      requestPromise = requestPromise.delete(url, { params: { ...body } });
      break;
    case 'PATCH':
      requestPromise = requestPromise.patch(url, body);
      break;
    case 'PUT':
      requestPromise = requestPromise.put(url, body);
      break;
  }
  return requestPromise
  .then(res => {
      if (actions) {
        if (!disableToast) actions.apiCallSuccess(res.data);
        if (actions.nullifyToast)
          setTimeout(() => {
            actions.nullifyToast();
          }, 3000);
      }

      return res;
    })
    .catch(err => {
      if (actions) {
        if (!disableToast) actions.apiCallFail(err);
        if (actions.nullifyToast)
          setTimeout(() => {
            actions.nullifyToast();
          }, 3000);
      }
      throw err;
    });
};
