import React, { useState, useRef } from "react";
import { history } from "../helpers/history";
import axios from "../api/index";
import moment from "moment";
import { useParams, generatePath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCircleCheck,
  faBookOpen,
  faSquareXmarkSolid,
  faCircleXmark,
  faCheckSquare as faSquareCheckRegular,
  faClock as faClockSolid,
  faCalendarDays,
  faChalkboardUser,
  faClipboardQuestion,
} from "@fortawesome/free-solid-svg-icons";
import checked from "../assets/images/checked.png";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loading: false,
      user: {},
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const userString = sessionStorage.getItem("user");
    const currentUser = JSON.parse(userString);

    if (!currentUser) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
    }
    if (!userToken) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
    }
    this.setState({
      loading: true,
      user: currentUser,
    });
    this.fetchdata();
  }

  fetchdata() {
    this.setState({
      loading: true,
    });
    return axios({
      url: "/api/home",
    })
      .then((res) => {
        this.setState({
          loading: false,
          results: res.data.videos,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  changeDialog = (e, index) => {
    e.preventDefault();
    this.setState({
      activeIndex: index,
    });
  };

  goToSession(video) {
    sessionStorage.setItem("video", video.id + "");

    history.push(`/session/${video.id}`);

    // history.push("/session");
    window.location.reload();
  }

  renderItem(result, index) {
    let now = new Date();
    now.setHours(0, 0, 0, 0);

    if (result.active === "0") {
      return (
        <div className="card text-center">
          <div className="card-header"> {result.title} </div>
          <div className="card-body">
            <FontAwesomeIcon icon={faCalendarDays} />
            :موعد المحاضرة يوم
            <div className="alert alert-info">
              {moment(result.date_available).format("DD-MM")}{" "}
            </div>
            <div className="row text-center">
              <div className="col-12">
                <p className="text">
                  المتكلم:
                  <div className="alert alert-info">{result.speaker}</div>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <div className="card">
            <div
              className="card-header"
              style={{
                color: "#333",
                backgroundColor: "rgba(197, 244, 247, 0.136)",
                padding: "10px",
              }}
            >
              <p style={{ margin: "0", fontWeight: "500", fontSize: "20px" }}>
                {result.title}
              </p>
            </div>

            <div className="card-body">
              <div className="row text-center">
                <div className="col-md-6">
                  <span className="custom-alert">
                    <span
                      style={{
                        fontSize: "18px",
                        float: "right",
                        color: "black",
                      }}
                    >
                      <span style={{ marginLeft: "30px" }}>:المحاضرة يوم </span>
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        style={{ marginLeft: "5px" }}
                      />
                    </span>
                    {moment(result.date_available).format("DD-MM")}
                  </span>
                  <div className="custom-text">
                    <span className="custom-alert">{result.speaker}</span>
                    :المتكلم
                    <FontAwesomeIcon
                      style={{ marginLeft: "5px" }}
                      icon={faChalkboardUser}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  {result.watched === true ? (
                    <div className="video-status">
                      <span className="status-text">
                        لقد قمت بمشاهدة الفيديو
                      </span>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ marginLeft: "5px" }}
                        className="status-icon"
                      />
                    </div>
                  ) : (
                    <p>
                      لم تشاهد الفيديو بعد
                      <FontAwesomeIcon
                        icon={faSquareCheckRegular}
                        style={{
                          marginLeft: "5px",
                          color: "red",
                          fontSize: "1.5rem",
                        }}
                      />
                    </p>
                  )}
                  <div className="grade-container" style={{ marginTop: "18%" }}>
                    <span
                      className="grade-label"
                      style={{
                        float: "right",
                        marginLeft: "0",
                        marginRight: "10px",
                        marginTop: "2%",
                      }}
                    >
                      <span style={{ fontSize: "18px" }}>:حالة الامتحان </span>
                    </span>
                    <span
                      style={{
                        float: "left",
                        marginRight: "5px",
                        width: "55%",
                        marginTop: "1%",
                      }}
                      className={`grade ${
                        result.quizzes[0].grade === "failed"
                          ? "grade-failed"
                          : result.quizzes[0].grade === "passed"
                          ? "grade-succeeded"
                          : "grade-pending"
                      }`}
                    >
                      {result.quizzes[0].grade}
                      {result.quizzes[0].grade === "failed" && (
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          style={{ marginLeft: "5px" }}
                        />
                      )}
                      {result.quizzes[0].grade === "passed" && (
                        <FontAwesomeIcon
                          icon={faSquareCheckRegular}
                          style={{ marginLeft: "5px" }}
                        />
                      )}
                      {result.quizzes[0].grade === "pending" && (
                        <FontAwesomeIcon
                          icon={faClockSolid}
                          style={{ marginLeft: "5px" }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-primary-custom btn-lg lec"
                onClick={() => {
                  this.goToSession(result);
                }}
                style={{
                  color: "white",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <span> اذهب إلى المحاضرة </span>
                <FontAwesomeIcon icon={faBookOpen} />
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { user, results } = this.state;
    return (
      <div className="container">
        <div className="header-container col-12 .col-md-8">
          <header
            className="jumbotron text-center"
            style={{
              background: "linear-gradient(135deg, #00bcd4 0%, #5458C9 100%)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <div className="container">
              <div className="text-right align-right col-6 .col-md-4">
                <h3>
                  <p
                    className="text-nowrap greeting"
                    style={{
                      fontSize: "1.4rem",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                      margin: 0,
                      padding: "10px 20px",
                      background: "transparent",
                      color: "white",
                      fontWeight: "300",
                      borderRadius: "10px",
                    }}
                  >
                    أهلاً! {user && user.name && user.name}
                  </p>
                </h3>
              </div>
            </div>
          </header>

          <header className="alert-box text-center">
            <h3>
              <p>
                <FontAwesomeIcon icon={faBell} className="icon" />
                هنا ستجد جميع المحاضرات وسيظهر زر "اذهب إلى المحاضرة" في وقت
                المحاضرة
              </p>
            </h3>
          </header>
        </div>

        <div className="scrollable-list">
          <ul>
            {results &&
              results.length > 0 &&
              results.map((result, index) => {
                return this.renderItem(result, index);
              })}
          </ul>
        </div>
      </div>
    );
  }
}
export default Profile;
